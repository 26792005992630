<template>
    <v-container fluid>
        <v-form @submit.prevent="getStations('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'station.create'}" color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('search_station') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'station.create'}" dark color="indigo">
                                    {{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="2">
                                <ValidationProvider ref="code" rules="min:1|max:30"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="code" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-barcode"
                                                  :label="$t('code')" color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="10">
                                <ValidationProvider ref="station_name" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="station_name" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-language-ruby-on-rails"
                                                  :label="$t('station_name')" color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-0 py-6">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat light>
            <v-card-text class="px-0">
                <v-data-table :headers="headers" :items="stationItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalStations" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="editStation(item)" :title="$t('edit')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteStation(item)" :title="$t('delete')">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-show="totalStations > perPage" v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"

    export default {
        name: "Stations",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                code: null,
                station_name: null,
                sortBy: "name",
                sortDir: true,
                loading: false,
                options: {},
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalStations: 0,
                stationItems: [],
                headers: [
                    {
                        text: this.$t('code'),
                        align: "left",
                        sortable: true,
                        value: "code"
                    },
                    {
                        text: this.$t('name'),
                        align: "left",
                        sortable: true,
                        value: "name"
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
            }
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
        },
        watch: {
            options: {
                handler() {
                    this.getStations()
                },
                deep: false
            }
        },
        methods: {
            editStation(item) {
                this.$router.push({
                    name: 'station.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async getStations(type) {
                var _this = this
                this.progress = 0
                this.loading = true
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options
                let params = {}
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0]
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc'
                }
                if (page !== undefined) {
                    params.page = page
                }
                if (type === 'search') {
                    params.page = 1
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage
                }
                if (this.station_name) {
                    params.station_name = this.station_name
                }
                if (this.code) {
                    params.code = this.code
                }



                await this.$http
                    .get("admin/station", {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.stationItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalStations = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.$toastr.success(this.$t('failed_to_get_list_stations'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async deleteStation(item) {
                if (confirm(this.$t('delete_station'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .delete(`admin/station/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('station_has_been_deleted'))
                            this.getStations()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('station_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            }
        }
    }
</script>
